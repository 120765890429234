
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './index.css'
import Enavbar from '../Enavbar';
import { AiOutlineForm } from 'react-icons/ai';

const ContactUsForm=()=>{


        return (
            <>
         
            <div className='form-registraion-container'>
               
            <Form className='form-container'>
            <h1 className='registration-heading'>Please fill and Get The Quote <AiOutlineForm style={{marginLeft:20}}/></h1>
                <Form.Group className="mb-4" controlId="formBasicText">
                  
                    <Form.Control type="text" placeholder="Enter Your Name" />
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                   
                    <Form.Control type="email" placeholder="Enter Your Email" />
                    
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicContact">
                   
                    <Form.Control type="tel" placeholder="Enter Contact No" />
                </Form.Group>

                <Form.Group className="mb-4">
                 
                    <Form.Select placeholder="Select Your Requirement" >
                    <option value="" disabled selected>Select Your Requirement</option>
                        <option>Web Design</option>
                        <option>Web Development</option>
                        <option>Mobile App Development</option>
                        <option>E-Commerce Website Design</option>
                        <option>SEO services</option>
                        <option>SEM services</option>
                        <option>SMM services</option>
                    </Form.Select>
                </Form.Group>
                <textarea rows='4' cols="40" placeholder="Your Requirement In Detail" style={{paddingLeft:10,paddingTop:10}}></textarea>
                <div className='mt-4 text-center'>
                <Button variant="primary" type="submit">
                    Send Message
                </Button>
                </div>
            </Form>
            </div>
            </>
        );
    
}

export default ContactUsForm;