import { HiEmojiHappy } from 'react-icons/hi'
import { AiFillProject } from 'react-icons/ai'
import { BsClock, BsGlobe, BsRobot, BsWhatsapp} from 'react-icons/bs'
import { CgAwards } from 'react-icons/cg'
import { FaMobileAlt } from 'react-icons/fa'

import { useAppStore } from '../AppStore/index'
import { useState } from 'react'

import './index.css'
import Chatbot from '../Chatbot'
import Footer  from '../Footer'

import SocialMediaIcons from '../SocialMediaIcons'




const Home = () => {

    const dopen = useAppStore((state) => state.dopen)

   
    const [isDropdown,setDropdown]=useState(false)

    const dropdown=()=>{
        setDropdown(prevState => !prevState)
       
    }
 

    return (
        <>
    
        <div className='app-container'>
            <div className='bg-container'>
               

                <div className='head-container'>
                    <h1 className='main-heading'>We are the passionate and innovate webdesign agency which <br />understands business</h1>
                    <br />
                    <p className="paragraph">As a web design agency, we believe that web design can engage people and influence them in the online presence to take a purchase decision instantly.</p>

                </div>
            </div>

            <div className='image-contenet-container'>
                <img className='analyze-img' src="https://img.freepik.com/free-vector/data-inform-illustration-concept_114360-864.jpg?w=996&t=st=1682076725~exp=1682077325~hmac=5a04ad271fbdebc988099609c1481da7380463d44dfb87819430215a66d8eb82" alt='analysis-img'/>
                <div className='impression-status-container'>
                    <div className='imp-content-card'>
                    <h1 className='impression-heading'>The First Impression is the Best Impression</h1>
                        <p>Emedha Technologies is a group of technocrats who possess the critical ability to understand the customer, their business model and market and <br />their core needs.</p>
                        <p>This is then architected into a bespoke technical solution that is tailored to the clients needs</p>
                    </div>
                    <h1 className='track-reacord-head'>Our Track Records</h1>
                    <div className='awards-container'>
                    <div className='awards-card'>
                    <HiEmojiHappy style={{ fontSize: 30 }}/>
                            <div className='d-flex flex-column align-items-center align-card'>
                                <h1 className='total-clients'>100</h1>
                                <p className='happy-para'>Happy Clients</p>
                            </div>
                    </div>
                    <div className='awards-card'>
                    <AiFillProject style={{ fontSize: 30 }} />
                            <div className='d-flex flex-column align-items-center align-card'>
                            <h1 className='total-clients'>60</h1>
                           <p className='happy-para'>Projects</p>
                            </div>
                    </div>
                    <div className='awards-card'>
                    <BsClock style={{ fontSize: 30 }} />
                            <div className='d-flex flex-column align-items-center align-card'>
                            <h1 className='total-clients'>16 </h1>
                          <p className='happy-para' >Years Of Experience</p>
                            </div>
                    </div>
                    <div className='awards-card'>
                    <CgAwards style={{ fontSize: 30 }} />
                            <div className='d-flex flex-column align-items-center align-card'>
                            <h1 className='total-clients'>30</h1>
                            <p className='happy-para'>Awards</p>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
            
            <div className='our-services'>
            <h1 style={{fontSize: 28, marginTop: 35 }} className='our-serv-head'>Our Services</h1>
                <div className='our-services-card-container'>
               
                    <div className='our-service-card'>
                        <img src='https://emedha.com/assets/img/icons/services/web-design.png' alt='service-app-logo'/>
                        <h1 className='service-card-heading' style={{ color: 'skyblue', marginTop: 15, marginBottom: 15 }}>Web Design</h1>
                        <p>The customers can be assured that they get a site with amazing features with all the navigational amenities which are designed to provide multiple methods of communication to the social media channels and platforms seamlessly.</p>
                    </div>


                    <div className='our-service-card'>
                        <img src='https://emedha.com/assets/img/icons/services/Mobile-APP-developer.png' alt='service-app-logo' />
                        <h1 className='service-card-heading' style={{ color: 'skyblue', marginTop: 15, marginBottom: 15 }}>Web Applications</h1>
                        <p>The customers can be assured that they get a site with amazing features with all the navigational amenities which are designed to provide multiple methods of communication to the social media channels and platforms seamlessly.</p>
                    </div>



                    <div className='our-service-card'>
                        <FaMobileAlt style={{ fontSize: 60, color: 'green' }} />
                        <h1 className='service-card-heading' style={{ color: 'skyblue', marginTop: 15, marginBottom: 15 }}>Mobile Applications</h1>
                        <p>The customers can be assured that they get a site with amazing features with all the navigational amenities which are designed to provide multiple methods of communication to the social media channels and platforms seamlessly.</p>
                    </div>


                    <div className='our-service-card'>
                        <BsGlobe style={{ fontSize: 60, color: 'lightgreen' }} />
                        <h1 className='service-card-heading' style={{ color: 'skyblue', marginTop: 15, marginBottom: 15 }}>Degital Marketing</h1>
                        <p>The customers can be assured that they get a site with amazing features with all the navigational amenities which are designed to provide multiple methods of communication to the social media channels and platforms seamlessly.</p>
                    </div>

                    <div className='our-service-card'>
                        <BsRobot style={{ fontSize: 60, color: 'lightgreen' }} />
                        <h1 className='service-card-heading' style={{ color: 'skyblue', marginTop: 15, marginBottom: 15 }}>Chatbot</h1>
                        <p>The customers can be assured that they get a site with amazing features with all the navigational amenities which are designed to provide multiple methods of communication to the social media channels and platforms seamlessly.</p>
                    </div>


                    <div className='our-service-card'>
                        <BsWhatsapp style={{ fontSize:60, color: 'lightgreen' }} />
                        <h1 className='service-card-heading' style={{ color: 'skyblue', marginTop: 15, marginBottom: 15 }}>What's App Business API</h1>
                        <p>The customers can be assured that they get a site with amazing features with all the navigational amenities which are designed to provide multiple methods of communication to the social media channels and platforms seamlessly.</p>
                    </div>
                </div>
            </div>
           
            <Footer/>
       <div className='social-media-container'><SocialMediaIcons/></div> 
            <div className='end-to-bottom'>
            <Chatbot/>
            </div>
            </div>
          
            
        </>



    )
}


export default Home



{/* <div className='analysis-container'>
                <img className='analysis-image' src="https://liveimages.algoworks.com/new-algoworks/wp-content/uploads/2022/06/07132456/service-based-companies-min.gif" alt='analysis-img' />
                <div className='acheive-goal-container'>
                    <div className='impression-card'>
                        <h1 className='impression-heading'>The First Impression is the Best Impression</h1>
                        <p>Emedha Technologies is a group of technocrats who possess the critical ability to understand the customer, their business model and market and <br />their core needs.</p>
                        <p>This is then architected into a bespoke technical solution that is tailored to the clients needs</p>
                        {/*<p>Our core competency is discovering and building novel technologies that enhance and make perfect sense to the needs and ambitions of a customer.</p> */}
            //             <p>We are proud to note that our solutions are industry independent and industry relevant at the same time!</p>
            //         </div>
            //         <h1 className='track-reacord-head'>Our Track Records</h1>
            //         <div className='status-card'>

            //             <div className='client-card'>
            //                 <HiEmojiHappy style={{ fontSize: 30 }} />
            //                 <div className='d-flex flex-column align-items-center'>
            //                     <h1 className='total-clients'>100</h1>
            //                     <p className='happy-para'>Happy Clients</p>
            //                 </div>
            //             </div>
            //             <div className=' client-card'>
            //                 <AiFillProject style={{ fontSize: 30 }} />
            //                 <div className='d-flex flex-column align-items-center'>
            //                     <h1 className='total-clients'>60</h1>
            //                     <p className='happy-para'>Projects</p>
            //                 </div>
            //             </div>
            //             <div className='client-card'>
            //                 <BsClock style={{ fontSize: 30 }} />
            //                 <div className='d-flex flex-column align-items-center'>
            //                     <h1 className='total-clients'>16 </h1>
            //                     <p className='happy-para'>Years Of Experience</p>
            //                 </div>
            //             </div>
            //             <div className='client-card'>
            //                 <CgAwards style={{ fontSize: 30 }} />
            //                 <div className='d-flex flex-column align-items-center'>
            //                     <h1 className='total-clients'>30</h1>
            //                     <p className='happy-para'>Awards</p>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div> */