import './index.css'
import { useState } from 'react'
import ChatForm from '../ChatForm'
import { IoMdClose } from 'react-icons/io'
import { BsFillSendFill } from 'react-icons/bs'

const Chatbot=()=>{


const [isOpenChatBox,setChatBox]=useState(false)

    const openChatBox=()=>{
        setChatBox((prevState)=>!prevState)
    }


    return(
        <div className='d-flex flex-column'>
        {isOpenChatBox?(<div style={{color:'white',marginRight:25,borderRadius:10}} className='p-4 chat-bot-width'>
        <div className='d-flex flex-row justify-content-between align-items-center'>
       
        {/* <img src="assets/img/logo3.png" style={{ width: 70,height:70,marginLeft:28,marginBottom:25}} alt='logo'/> */}
        </div>
        <div className='chat-container'>
          <div className='logo-container'>
              <img src='https://emedha.com/assets/img/logo3.png' className='mt-2'/>
              <IoMdClose className='icon' style={{fontSize:40,color:'white'}} onClick={openChatBox}/>
              
          </div>
          <hr style={{color:'black'}}/>
          <div className='chat-send-container'>
          <div className='text-container'>
            <p>Hi...! I am your virtual assistant. Welcome to e-Medha Technologies a one stop solutions for all your web/digital needs.</p>
           

          </div>
          <div>
            <input type='text' className='input-box'/>
             <BsFillSendFill style={{fontSize:30,color:'black',border:'1px solid FaBlackTie'}}/>
          </div>
     
          </div>
      </div>
        {/* <IoMdClose className='icon' style={{fontSize:40}} onClick={openChatBox}/> */}
        </div>):(<div className='end-to-bottom'>
            <img onClick={openChatBox} src='https://emedha.com/gif/animate-unscreen.gif' style={{height:138,width:148}} aria-hidden="true"/>
        </div>)}
       </div>
    )
}

export default Chatbot


