import Enavbar from '../Enavbar'
import { BsClock, BsGlobe, BsRobot, BsWhatsapp,BsFacebook,BsInstagram,BsLinkedin } from 'react-icons/bs'
import {ImTwitter} from 'react-icons/im'

import './index.css'
import Footer from '../Footer'


const Approach = () => {

    return (
        <>
           
            <div className='approach-container'>
                <img src='https://edgegrowth.com/wp-content/uploads/2022/08/Edge-Growth-Esd-Our-Approach-Banner.jpg' alt='approach-img' className='approach-img' />
                <div className='work-process-container'>
                    <h1 className='our-work-head'>Work Process</h1>
                    <div className='approach-card'>
                        <img src='https://img.freepik.com/free-vector/business-team-magnifying-glass-document_1262-19221.jpg?w=826&t=st=1681712407~exp=1681713007~hmac=c3fa96bf09048b485742b83ecad4b8438fe144ba6886838e117908bdcf876bae' alt='identify-img' className='identify-img' />
                       <div className='description-card m-2'>
                        <h1 className='our-resource-card-heading'>Identify Needs</h1>
                        <p className='identiy-description'>

                            Our team first goes through a sit-down with the client to identify needs. We read between the lines and isolate critical <span style={{color:'rgb(250, 111, 111)',fontWeight:'bold'}}>“Pain Points” </span>in our customer’s experience and make this the cornerstone of our solution.</p>
                    </div>
                    </div>
               
                    <div className='approach-card'>
                       
                       <div className='description-card m-2'>
                        <h1 className='our-resource-card-heading'>Create Process Flow</h1>
                        <p className='identiy-description'>

                        Once the PAIN POINTS are identified the next step we do is create Process Flow to develop solutions that comprehensively address the needs.</p>
                    </div>
                    <img src='https://emedha.com/assets/img/workprocess/Create-Process-Flow-min.png' alt='identify-img' className='identify-img' />
                    </div>
            
                    <div className='approach-card'>
                        <img src='https://img.freepik.com/free-vector/product-iteration-concept-illustration_114360-4725.jpg?w=740&t=st=1681713798~exp=1681714398~hmac=568388d32c74b71f38a316d21b3846cd41145b25fd93837fc699c1bc0c3a385a' alt='identify-img' className='identify-img' />
                       <div className='description-card m-2'>
                        <h1 className='our-resource-card-heading'>Write Operating Procedures</h1>
                        <p className='identiy-description'>

                        Today the key to success for any SMEs or MEMEs or for the matter any business process is its SOP. So, in eMedha we before start our real technical documentation we write the Operating Procedures for our client needs.</p>
                    </div>
                    </div>
              
                    <div className='approach-card'>
                                              <div className='description-card m-2'>
                        <h1 className='our-resource-card-heading'>Develop Work Plan</h1>
                        <p className='identiy-description'>

                        The very next step for a successful deployment is Develop a clear Work Plan. In eMedha we have experts to develop the Work Plan in such a way that every NEEDS of the client is addressed.</p>
                    </div>
                    <img src='https://img.freepik.com/free-vector/time-management-planning-isometric-cillustration_1284-56326.jpg?w=826&t=st=1681714642~exp=1681715242~hmac=66a23a788d2a05c8aa2a4ac9f8e17e4df598dfb7d9888de0ea8700b54ee95825' alt='identify-img' className='identify-img' />

                    </div>
                    <div className='approach-card'>
                        <img src='https://img.freepik.com/free-vector/analysis-concept-illustration_114360-1119.jpg?w=740&t=st=1681714416~exp=1681715016~hmac=3eaeecbaee556389f3d415890ab028b1a0eb10142026266b1ba7edab614a0a51' alt='identify-img' className='identify-img' />
                       <div className='description-card'>
                        <h1 className='our-resource-card-heading'>Technical Research</h1>
                        <p className='identiy-description'>

                        We then thoroughly study the different approaches currently present to solve a particular need, and develop an in-house solution that is optimal and produces significant results.</p>
                    </div>
                    </div>
                  
                    <div className='approach-card'>
                         
                       <div className='description-card m-2'>
                        <h1 className='our-resource-card-heading'>Deliver & Maintain</h1>
                        <p className='identiy-description'>
                        In eMedha we strictly and meticulously deliver on time and as well maintain the system thereafter with atmost care. Today we can proudly say we solve any complex problem of our client within 1 business working day.</p>
                    </div>
                    <img src='https://emedha.com/assets/img/workprocess/Deliver%20&%20Maintain.png' alt='identify-img' className='identify-img' />
                    </div>
                </div>
            </div>
           
            <Footer/>
        </>
    )

}


export default Approach