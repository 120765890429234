import Enavbar from '../Enavbar'
// import {AiOutlinePlus} from 'react-icons/ai'
import {Button} from 'react-bootstrap'
import './index.css'

const Portfolio=()=>{

    return(
        <>
    
        <img src='https://www.ics-dubai.com/content/images/portfolio-banner.jpg' alt='approach-img' className='approach-img' />
        <div className='d-flex f-lex-column justify-content-center'>
      
        <div className='portfolio-container'>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p1.png" alt='project1'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p2.png" alt='project2'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p3.png" alt='project3'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p4.png" alt='project4'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p5.png" alt='project5'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p6.png" alt='project6'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
    
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p8.png" alt='project7'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p9.png" alt='project8'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p14.png" alt='project9'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p11.png" alt='project10'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p12.png" alt='project11'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            <div className='image-box'>
               <img src="https://emedha.com/assets/img/portfolio/p13.png" alt='project12'/>
               
               <Button style={{marginTop:25}}>See More</Button>
            </div>
            </div>
        </div>
        </>
    )
}

export default Portfolio