import Enavbar from './components/Enavbar';
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'

import './App.css';
import Sidebar from './components/Sidebar';

import { BrowserRouter } from 'react-router-dom';



const App = () => {
    return (
      <>
      <BrowserRouter><Enavbar/> <div className='side-bar'><Sidebar/></div></BrowserRouter>
   
 
    
      </>
    )}

export default App;
