import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Enavbar from '../Enavbar'
import {CiMail} from 'react-icons/ci'
import {IoMdCall,IoIosHome, IoIosCall} from 'react-icons/io'
import {BsFacebook} from'react-icons/bs'
import {TiSocialYoutubeCircular} from 'react-icons/ti'
import './index.css'
import { AiFillTwitterCircle, AiOutlineHome, AiOutlineMail } from 'react-icons/ai';
import { ImGooglePlus3 } from 'react-icons/im';
import { BiRightDownArrowCircle } from 'react-icons/bi';
import { MdLocationPin } from 'react-icons/md';
import  followus from '../Images/followus.jpg.png'
import Footer from '../Footer';
import { useState } from 'react';
import ContactUsForm from '../ContactUsForm';

const Contact = () => {

    const [isOpenContact,setContactForm]=useState(false)

    const openContactForm=()=>{
        setContactForm(true)
    }

    return (
        <>
        {isOpenContact?<ContactUsForm/>:
        <>
            <div className='contact-container'>
            <div className='contact-form-container'>
              
                    <Form className='get-touch-card'>
                        <h1 className='registration-heading'>Please fill and Get it touch</h1>
                        <Form.Group className="mb-4" controlId="formBasicText">

                            <Form.Control type="text" placeholder="Enter Your Name" />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">

                            <Form.Control type="email" placeholder="Enter Your Email" />

                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicContact">

                            <Form.Control type="tel" placeholder="Enter Mobile No" />
                        </Form.Group>


                        <textarea rows='3' cols="30" placeholder="Message" style={{paddingLeft:10,paddingTop:10}}></textarea>
                        <div className='mt-4 text-start'>
                            <Button variant="primary" type="submit">
                                Send Message
                            </Button>
                        </div>
                    </Form>
                
                <div className='contact-us-card' style={{backgroundColor:'#1c1f18'}}>
                        <h1 className='contact-us-heading'>Contact Us </h1>
                        <p className='contact-type'><CiMail style={{marginRight:8,color:'red'}}/> info@emedha.com</p>
                        <p className='contact-type'><IoMdCall style={{marginRight:8,color:'red'}}/> +91 9849164361</p>
                        <p className='contact-type text-start'><IoIosHome style={{marginRight:14,color:'red'}}/>Flat #203, MYTRI APARTMENTS, ECIL POST, Hyderabad-500062, Telangana, INDIA</p>

                </div>
                <div className='follow-us-icons'>
                    <img src={followus} className='follow-us-img'/>
                    <div style={{marginTop:0,marginBottom:20}}>
                    <a href='https://www.facebook.com/emedha' target='_blank'><BsFacebook className='follow-social-icon'/></a>
                    <a href='https://twitter.com/emedha'><AiFillTwitterCircle className='follow-social-icon2'/></a>
                    <a href="link"><ImGooglePlus3 className='follow-social-icon'/></a> 
                    <a href="link"><TiSocialYoutubeCircular className='follow-social-icon2'/></a>
                    </div>
                </div>
               
            </div>
            <div className='enquiry-card-contact'>
                    <h1 className='enquiry-head'>Please Fill out the form to do an Enquiry.Thank You.....</h1>
                    <Button style={{ marginRight: 40}} variant="outline-dark" className='button-enquiry' onClick={openContactForm}>Enquiry</Button>
                </div>
            </div>
           <Footer/>  </>
    }
      </>
    )
}

export default Contact


