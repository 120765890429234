import './index.css'
import smm2 from '../Images/smm2.png'
import Footer from '../Footer'


const SmmServices = () => {

    return (
        <>
            <div className='smm-container-bg'>
                <img className='smm-bg-img' src={smm2} />
                <h1 className='smm-main-heading'>We harness the power of social media to drive sales through social media marketing service in Hyderabad</h1>
            </div>
            <div className='smm-cards-container'>
                <div className='smm-card'>
                    <img className='smm-card-img' src='https://img.freepik.com/free-vector/social-media-marketing-banner_80328-38.jpg?w=740&t=st=1681819902~exp=1681820502~hmac=57c2138d95e714f90aca2373c02f7b89bed06ba279383915e9bb54107fd0ecdf' />
                    <div className='smm-card-contet'>
                        <p>In the constantly evolving digital ecosystem, many product entrepreneurs do not have that extensive internet marketing knowledge or do not have the kind of time and patience to float their products and services to the digital channels which otherwise connects to the potential onlookers. The result of these disconnects, many brands struggle for a prolonged period of time to really connect through their marketing paradigms. Entrepreneurs at this stage of teething problems with their brand promotions, can either resort to learning social media channels and float their branding strategies one by one or outsource the Social Media Optimization (SMO) services to a professional team who would work with you and attempt to enhance your brand seamlessly.

                            
                            Emedha Technologies has been the pioneers in Social Media Optimization and we have been quite reputed in the marketplace as a social media marketing services in Hyderabad, as we conceive the SMO initiatives which increases brand enhancement & awareness, constantly getting connected to the target audience.</p>
                    </div>

                </div>
                <div className='smm-card2-container'>
                    <div className='smm-card2'>
                        <h1 className='smm-card-heading'>As a best practice of SMO services, we carry out the following initiatives:</h1>
                        <img className='smm-card2-img' src="https://img.freepik.com/free-vector/marketing-concept-illustration_114360-7324.jpg?w=740&t=st=1681820765~exp=1681821365~hmac=3e7f4e4f4d648980bf8f3278cf3dbea2d7333016054cad2ab0ad9343ca74e104" />
                        <p>As a social media marketing company, the team is instrumental in gaining an overall control over the marketplace and involved in the optimization process of social media. We have gained popularity by most product companies in being the service provider for</p>

                        <p>The gap-analysis between the customer needs and the product & service fulfillment of the brand.</p>
                        <p>The gap-analysis could also could enhance the brand equity of the brand and strengthening the engagement relationships.</p>
                    </div>
                    <div className='smm-card3'>
                        <h1 className='smm-card-heading'>The copywriters who become your brand ambassadors in effective messaging:</h1>
                        <img className='smm-card2-img' src="https://img.freepik.com/premium-vector/smm-text-laptop-screen-tiny-people-work-social-media-managers-digital-marketing-online_501813-703.jpg?w=900" />
                        <p>The in-house copywriting team vehemently create multiple product profiles in various social media platforms and collaborate with social media marketing (SMM) professionals.</p>
                        <p>Various product write ups and technical descriptions of the product, which also includes the technical blogs related to the product are regularly posted on SMP’s.
                        </p>
                    </div>

                </div>
            </div>
            <Footer/>
        </>

    )
}

export default SmmServices