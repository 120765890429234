import {TbSend} from 'react-icons/tb'

import { BsFacebook, BsWhatsapp } from 'react-icons/bs'

import { IoMdCall } from 'react-icons/io'
import { AiFillLinkedin } from 'react-icons/ai'
import './index.css'


const SocialMediaIcons=()=>{
    return(
    <div className='social-media-container'>
        <div className='social-card'>
            <a href='https://t.me/Emedha_bot' target='_blank' rel="noreferrer" ><TbSend className='social-icons'/></a>
            <div className='line-social'></div>
            <AiFillLinkedin className='social-icons'/>
            <div className='line-social'></div>
            
            <BsWhatsapp className='social-icons'/>
            <div className='line-social'></div>
            <IoMdCall className='social-icons'/>
            <div className='line-social'></div>
            <a href='https://www.facebook.com/emedha' target="_blank" rel="noreferrer" ><BsFacebook className='social-icons'/></a>
        </div>
    </div>
    )
}


export default SocialMediaIcons