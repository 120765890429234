import { useAppStore } from '../AppStore/index'

import {RiArrowDownSLine} from 'react-icons/ri'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import './index.css'


const Sidebar = () => {
    
    const NavItemsClicked = () => {
        updateState(!dopen)
      }
      const updateState = useAppStore((state) => state.updateOpen)

    const dopen = useAppStore((state) => state.dopen)

   
    const [isDropdown,setDropdown]=useState(false)

    const dropdown=()=>{
        setDropdown(prevState => !prevState)
       
    }

    const [isDropdown2,setWebappDevelop]=useState(false)

    const dropdown2 =()=>{
        setWebappDevelop(prevState=>!prevState)
    }
 
    const [isDropdown3,setWMobappDevelop]=useState(false)

    const dropdown3=()=>{
        setWMobappDevelop(prevState=>!prevState)
    }

    
    const [isDropdown4,setDigitalMarket]=useState(false)

    const dropdown4=()=>{
        setDigitalMarket(prevState=>!prevState)
    }


    return (
        <>
        <div className='d-flex flex-column side-bar sidebar-bg-container'>
          
            {dopen && (
                <div className='sidebar-bg-container'>
                <div className="sidebar-container d-flex flex-column" style={{ position: 'fixed' }}>
                    <ul style={{ listStyleType: 'none' }} className='ul1'>
                <Link to="/" onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='list-item' style={{marginTop:20}} >Home</li></Link> 
               
                <Link to="/about" onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='list-item' >About</li></Link>
                <Link to='/approach' onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='list-item'>Approach</li></Link> 
             <div className='d-flex flex-row justify-content-between align-items-center'><li className='list-item2' onClick={dropdown}>Services </li><RiArrowDownSLine className='icon' style={{color:'skyblue'}}/></div>
                        {isDropdown?<ul style={{ listStyleType: 'none' }} className='drop-container'>
                        <Link to="/webdesign" onClick={NavItemsClicked} style={{ textDecoration: 'none'}}> <li className='drop-items'>Web Designing</li></Link>    
                        <Link style={{ textDecoration: 'none'}}><li className='drop-items' onClick={dropdown2}>Web App Development <RiArrowDownSLine className='drop-icon'/></li></Link>
                            {isDropdown2?<ul style={{listStyleType:'none'}} className='drop-container'> <Link to='/pos' onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='drop-items'>POS</li></Link>
                           <Link to='/smm' onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='drop-items'>SMM</li></Link></ul>:null}
                           <Link style={{ textDecoration: 'none'}}><li className='drop-items' onClick={dropdown3}>Mobile App Development <RiArrowDownSLine className='drop-icon'/></li></Link>  
                            {isDropdown3?<ul style={{listStyleType:'none'}}className='drop-container'><Link to='/android' style={{ textDecoration: 'none'}}><li className='drop-items' onClick={NavItemsClicked}>Andriod App Development</li></Link><li className='drop-items'>iOS App Development</li></ul> :null}
                            <Link style={{ textDecoration: 'none'}}><li className='drop-items' onClick={dropdown4}>Digital Marketing <RiArrowDownSLine className='drop-icon'/></li></Link>
                            {isDropdown4?<ul style={{listStyleType:'none'}}className='drop-container'> <Link to='/seo' onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='drop-items'>SEO Services</li></Link><li className='drop-items'>SEM Services</li><Link to='/smm' onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='drop-items'>SMM Services</li></Link></ul>:null}
                            <Link to="/bot" onClick={NavItemsClicked} style={{ textDecoration: 'none'}}> <li className='drop-items'>Bot</li></Link>  
                            <Link to='/whatsapp' onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='drop-items'>What's App Business API</li></Link>    
                        </ul>:null}
                        <Link to='/portfolio' onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='list-item'>Portfolio</li></Link>  
                        <Link to='/contact' onClick={NavItemsClicked} style={{ textDecoration: 'none'}}><li className='list-item'>Contact</li></Link>
                    </ul>
                    </div>
                    </div>
            )}
            </div>
         
          </>
            )

            }

export default Sidebar