
import Enavbar from '../Enavbar'
import { FaRegHandPointRight } from 'react-icons/fa'
import { BiRightDownArrowCircle } from 'react-icons/bi'
import { AiOutlineMail, AiOutlineHome } from 'react-icons/ai'
import { IoIosCall } from 'react-icons/io'
import { MdLocationPin } from 'react-icons/md'
import { Button } from 'react-bootstrap'
import './index.css'
import ContactUsForm from '../ContactUsForm'
import { useState } from 'react'

const About = () => {


    const [isOpenContactForm,setContactForm]=useState(false)
    
    const openContactUsForm=()=>{
        setContactForm(true)
    }

    return (


        
         <>
           
           {isOpenContactForm?<ContactUsForm/>:<> 
           <div className='about-bg-img'>
                {/* <h1 className='about-heading' style={{ color: 'Highlight' }} >Custom Web developers in Hyderabad who can conceive and create designs which matches your perception</h1> */}
            </div>
            <div className='about-us-container'>

                <div className='about-us-card1'>
                    <div className='about-card'>
                        <h1 style={{ color: '#0cb4f1' }}>About Us</h1>
                        <p style={{ fontFamily: 'initial' }}>eMedha Technologies has been the brainchild of technical architects which has been able to nurture some of the seasoned professionals who have proven to possess the best technological expertise to implement the advanced platforms which are tailor made, flexible and adaptive to bring about the finest web services and represent as the finest web design company in Hyderabad.

                            The company since inception has been persistently and overwhelmingly in innovative development that matters to customers, some of the specific areas that we embark upon are mobile apps with web services thus eliminating code changes, incorporating data sciences, Artificial Intelligence (AI) and Machine Learning (ML) techniques on the industry specific data.<span style={{ marginLeft: 10 }}><Button variant="outline-primary">Read More</Button></span></p>
                    </div>
                    <img className='office-image' src='https://img.freepik.com/free-photo/friendly-atmosphere-group-young-freelancers-office-have-conversation-smiling_146671-13630.jpg?w=996&t=st=1681545466~exp=1681546066~hmac=26eae6332d8d901eef77fcca98d528735153c15e23c4fba110d2afcc4c13c270' />
                </div>
                <p style={{ fontFamily: 'initial' }} className='about-dscription'>We conceived and created designs that truly speak of our aesthetic thoughts which entice the potential audience towards taking up the purchase decisions. We are constantly driven with a mission to provide excellent service with best design pursuits to our valued customers at a very reasonable price.</p>
                <div className='guid-lines-cards-container'>
                    <div className='guid-line-card'>
                        <h1 className='guid-line-head'>We follow strict guidelines when taking up web Service Projects:</h1>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} />Before we even attempt to initiate any project, the technical team prepares a wire-frame considering every single aspect of the requirement specifications.</p>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} /> The purpose of having a wire-frame is to make sure that the customer and our technical team go on a perfect sync, in order to produce a customised product which adds value to the investment done by the customer.</p>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} />  The project propels further, <span style={{ fontWeight: 'bold', color: 'Highlight' }}>with Creative Web Design, Copywriters, and Custom Web Application Developers</span> all working together to create an effective project strategy which generates more customer conversions and aimed to bring more return on investment (ROI) to the customer.</p>

                    </div>
                    <div className='guid-line-card'>
                        <h1 className='guid-line-head'>Our Approach towards Projects:</h1>
                        <p>We have a single, straightforward approach while we work with projects that come our way.</p>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} />We are always admired by customers to create great and enhanced website which fulfils the interim requirements.</p>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} />We have a rich team of technical consultants in-house who are very focused during the making of the project.</p>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} />We are always concerned to bring the best in web services and always concerned to bring the highest return on investments (ROI) to the business.</p>

                    </div> 
                    <div className='guid-line-card mb-0 card3'>
                        <h1 className='guid-line-head'>Our strengths have been the following:</h1>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} />Increase traffic persistently and work towards the lead generation methods.</p>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} />Our testimony reflects in the way we create and establish brands in the marketplace.</p>
                        <p className='guid-line-description'><FaRegHandPointRight style={{ fontSize: 20, marginLeft: 5, marginRight: 5, color: 'Highlight' }} />We maintain our standards and that is showcased in every web design project that we undertake.</p>

                    </div>


                </div>
                {/* <h1 className='digital-platform-head'>The digital platforms that we work upon:</h1> */}
                <div className='digital-platform-container'>
                    <div className='digital-platform-card'>
                        <h1 className='digital-heading'>Search Engine Optimization:</h1>
                        <p className='digital-description'>We transform ourselves as the digital brand that runs your digital ecosystem</p>
                        <p className='digital-sub-description'> As a digital partner of the client, we understand the competitor market for the product and service of the product company and eventually get into the deep research to make you sustain in the online world for a prolonged time. As a SEO company in Hyderabad, we know the tricks of the trade and persistently work towards positioning you on the top of the Search Engine Results Page (SERP)<span style={{ marginLeft: 10 }}><Button variant="outline-primary">Read More</Button></span> </p>

                    </div>
                    <div className='digital-platform-card'>
                        <h1 className='digital-heading'>Search Engine Marketing:</h1>
                        <p className='digital-description'>We help brands in establishing truly potential through Search Engine Marketing (SEM)</p>
                        <p className='digital-sub-description'>We help the brands to increase their revenue through Search Engine Marketing and persistently work in positioning the Google ad-campaigns. The team of SEM professionals helps the website to get qualified hits to eventually increase the sales funnel<span style={{ marginLeft: 10 }}><Button variant="outline-primary">Read More</Button></span> </p>

                    </div>
                    <div className='digital-platform-card '>
                        <h1 className='digital-heading'>Social Media Optimization:</h1>
                        <p className='digital-description'>Increase your brand reach through social media optimization services (SMO)</p>
                        <p className='digital-sub-description'>Emedha has been dwelling much into the social media optimization world and have matured since then to persistently research on various social media platforms. As social media experts we enable your website in such a way that it receives more web traffic from social media channels and platforms eventually leading to significant return on investment (ROI)<span style={{ marginLeft: 10 }}><Button variant="outline-primary">Read More</Button></span> </p>

                    </div>

                </div>
                <div className='enquiry-card'>
                    <h1 className='enquiry-head'>Please Fill out the form to do an Enquiry.Thank You.....</h1>
                    <Button style={{ marginRight: 40 }} variant="outline-dark" className="button-enquiry" onClick={openContactUsForm}>Enquiry</Button>
                </div>
            </div>
            <div className='footer-section'>
                <div className='responsive-container'>
                    <div className='all-section-container'>
                        <div className='about-us-1'>
                            <h1 className='about-us-head' style={{ color: '#0cb4f1' }}>About Us </h1>
                            <p className='about-us-description' style={{ color: 'white' }}>Technology has brought about a rapid turnaround in the dynamics of today's business. With the growing challenges both in the business as well as technological domains, there is a dire need to concentrate on core competencies, and to have shorter product lifecycles.</p>
                        </div>

                        <div className='quick-links-1'>
                            <h1 className='quick-link-head' style={{ color: '#d1476f' }}>Quick Links</h1>
                            <a href='link' style={{ color: '#0cb4f1', fontWeight: 100 }}><h4 className='quick-link-items1'>Responsive-Web-Design</h4></a>
                            <a href='link' style={{ color: '#0cb4f1', fontWeight: 100 }}><h4 className='quick-link-items1'>Android-App-Development</h4></a>
                            <a href='link' style={{ color: '#0cb4f1', fontWeight: 100 }}>  <h4 className='quick-link-items1'>iOS-App-Development</h4></a>
                            <a href='link' style={{ color: '#0cb4f1', fontWeight: 100 }}><h4 className='quick-link-items1'>Social-Media-Marketing</h4></a>
                        </div>
                        <div className='contact-us1' style={{ marginBottom: 20 }}>
                            <h1 className='quick-link-heads' style={{ fontWeight: 'bold' }}>Get In Touch</h1>
                            <p className='contact-items'><AiOutlineMail style={{ marginRight: 8, color: 'green', fontSize: 22 }} />info@emedha.com</p>
                            <p className='contact-items'><IoIosCall style={{ marginRight: 8, color: 'green', fontSize: 22 }} />+919849164361</p>
                            <p className='contact-items'><AiOutlineHome style={{ marginRight: 8, color: 'green', fontSize: 22 }} />Flat #203, MYTRI APARTMENTS,    ECIL POST, Hyderabad-500062, Telangana, INDIA</p>
                            <p className='contact-items'><MdLocationPin style={{ marginRight: 8, color: 'green', fontSize: 22 }} /><a>View Location On Map</a></p>
                        </div>


                    </div>


                    <div className='footer-line1'>
                        <h1 className='copy-right'>Copyright  <span style={{ color: '#0cb4f1' }}>© </span>2018 - All rights reserved. Desinged by Emedha Technologies</h1>
                        <div className='d-flex flex-row align-items-center' style={{ color: 'white' }}><p style={{ marginRight: 10 ,marginTop:8}}>Home</p>
                            <p style={{ marginRight: 10 }}>About</p>
                            <p style={{ marginRight: 10 }}>Site Map</p>
                            <p style={{ marginRight: 10 }}>Contact</p>
                        </div>
                    </div>
                </div>
            </div></>

            }
</>
        
    )
}

export default About