
import Footer from '../Footer'
import './index.css'



const SeoServices = () => {

    return (
        <>
        <div className='seo-container'>
            <h1 className='seo-main-heading'>The SEO providers in Hyderabad who work persistently to enhance the lead generation</h1>
            <div className='seo-cards-container'>
                <div className='seo-card'>
                    <img src='https://img.freepik.com/free-vector/seo-analysis-isometric-composition-with-web-optimization-symbols_1284-32010.jpg?w=740&t=st=1681813411~exp=1681814011~hmac=273c06c6296ae64a4183fe9bb7b1d60b60feebdf793ea4f6608773ad9b495c77' className='seo-img' />
                    <div className='seo-content-card'>
                        <p>It is estimated that in the digital world, nearly half of all the internet traffic that is generated has been found to originate from a spectrum of hand-held devices. In this particular transition, one can really understand that if your business website becomes a responsive or the mobile friendly website there would be a fair chance for your website to tap the potential web traffic and seamlessly convert them into sales funnel. These indexed web pages are the most relevant as per the search term used by the user during their search. This relevancy is determined by Search Engine Optimization (SEO) methods which are impregnated to the web pages, which are made available in the Search Engine Results Page (SERP) when a user searches</p>
                    </div>
                </div>
                <div className='seo-card'>

                    <div className='seo-content-card'>
                        <h1 className='seo-card-content-head'>Emedha Technologies have remained the SEO providers in Hyderabad :</h1>

                        <p> We have years of expertise in the online platform and understand the client's unique selling proposition (USP) and the key performance indicators (KPIs).</p>
                        <p> We offer SEO services so that the customers get to transact in the digital medium and connect multiple audiences seamlessly </p>
                    </div>
                    <img src='https://img.freepik.com/premium-vector/seo-search-engine-optimization-concept-vector-illustration_185038-486.jpg?w=1060' className='seo-img' style={{margin:0}}/>
                </div>
                <div className='seo-card'>
                    <img src='https://img.freepik.com/free-vector/seo-isometric-concept-with-monitoring-traffic-symbols-isolated_1284-32006.jpg?w=826&t=st=1681814563~exp=1681815163~hmac=7312cca2aa3996651118c90a4449103d51b5cf019719b526cd6005488f4a51cc' className='seo-img' />
                    <div className='seo-content-card'>
                        <p>It is estimated that in the digital world, nearly half of all the internet traffic that is generated has been found to originate from a spectrum of hand-held devices. In this particular transition, one can really understand that if your business website becomes a responsive or the mobile friendly website there would be a fair chance for your website to tap the potential web traffic and seamlessly convert them into sales funnel. These indexed web pages are the most relevant as per the search term used by the user during their search. This relevancy is determined by Search Engine Optimization (SEO) methods which are impregnated to the web pages, which are made available in the Search Engine Results Page (SERP) when a user searches</p>
                    </div>
                </div>

            </div>
        </div>
        <Footer/>
        </>
    )
}


export default SeoServices