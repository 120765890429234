import { BsClock, BsGlobe, BsRobot, BsWhatsapp, BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs'
import { ImTwitter } from 'react-icons/im'
import './index.css'


const Footer = () => {

    return (
        <div className='footer-section'>
            <div className='responsive-container'>
                <div className='about-us-section'>
                    <div className='about-us-card'>
                        <h1 className='about-us-head' style={{ color: '#0cb4f1' }}>About Us </h1>
                        <p className='about-us-description' style={{ color: 'white' }}>Technology has brought about a rapid turnaround in the dynamics of today's business. With the growing challenges both in the business as well as technological domains, there is a dire need to concentrate on core competencies, and to have shorter product lifecycles.</p>
                    </div>
                    <div className='input-container'>



                        <input type='text' id='subscribe' className='input-box' />

                        <button className='subscribe-btn'>Subscribe</button>
                    </div>
                </div>
                <div className='card-container'>
                    <div className='quick-links'>
                        <h1 className='quick-link-head'>Quick Links</h1>
                        <h4 className='quick-link-items'>Home</h4>
                        <h4 className='quick-link-items'>About Us</h4>
                        <h4 className='quick-link-items'>Services</h4>
                        <h4 className='quick-link-items'>Terms of service</h4>
                        <h4 className='quick-link-items'>Privacy Policy</h4>
                    </div>
                    <div className='contact-us' >
                        <h1 className='quick-link-head'>Contact Us</h1>
                        <p className='quick-link-items'>Flat #203, MYTRI APARTMENTS,
                            ECIL POST, Hyderabad-500062,
                            Telangana, INDIA
                            Phone: +91 9849164361
                            Email: info@emedha.com</p>

                    </div>
                    <div className='our-newslettter' style={{ marginBottom: 20 }}>
                        <h1 className='quick-link-head'>Our Newsletter</h1>
                        <p className='quick-link-items'>
                            Global movement springs from individuals. It takes an entire team united behind something big. Together, we work hard, we laugh a lot, we brainstorm nonstop, we use hundreds of Post-Its a week, and we give the best high-fives in town.</p>
                    </div>
                    <div className='d-flex flex-column folo-card' >
                        <h1 className='folo'>Follows Us</h1>
                        <div className='d-flex flex-row align-items-center icons-container' >
                            <ImTwitter className='icons' style={{ backgroundColor: '#0cb4f1' }} />
                            <BsFacebook className='icons' style={{ backgroundColor: 'skyblue' }} />
                            <BsInstagram className='icons' style={{ backgroundColor: 'rgb(228, 207, 91)', color: 'black' }} />
                            <BsLinkedin className='icons' style={{ backgroundColor: 'orange' }} />
                        </div>
                    </div>
                </div>

            </div>
            <div className='footer-line'></div>
            <h1 className='copy-right'><span style={{ color: '#0cb4f1' }}>© </span>Copyright eMedha. All Rights Reserved</h1>
        </div>
    )

}


export default Footer