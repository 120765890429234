import { BsClock, BsGlobe, BsRobot, BsWhatsapp,BsFacebook,BsInstagram,BsLinkedin } from 'react-icons/bs'
import {ImTwitter} from 'react-icons/im'
import './index.css'


const AndriodAppService = () => {

    return (
        <>
            <div className='android-app-dev-container'>
                <div className='our-android-services'>
                    <div className='android-card'>
                        <img src='https://img.freepik.com/premium-vector/people-creating-together-new-app_23-2148683053.jpg?w=740' className='android-app-mobile-img1' />
                        <p className='android-app-description'>The android platform has transformed itself as a vital and an impeccable application development platform as more and more users tend to use android apps for a plethora of navigational needs. It has turned out to be a realistic boon for business owners and startup companies who constantly wanted to tap the users who predominantly use the android apps. As a business owner, when you have got an idea to develop a specific app which enables your business to happen seamlessly, we as a android app development company in Hyderabad discuss about the functionalities of the app that the customers wants to customise further enabling the app which gets released as per the specific scope.</p>
                    </div>
                    <div className='android-card card2'>
                        <img src='https://img.freepik.com/free-vector/app-development-illustration_52683-47931.jpg?w=996&t=st=1681805769~exp=1681806369~hmac=cd1c7521a1caaa9c01fb33849aaac8e797b02c0a312fe1d604ebecd92f93724b' className='android-app-mobile-img' />
                        <p className='android-app-description'>In the mobile world, there is no big or small projects when talked about android apps, a simple idea can become a strategic point when the technology solution provider has the competency and capability to deliver the apps which makes all the difference. Emedha Technologies have always been the contender in the market to have hand picked the specialised team who have got the leverage to build nonpareil apps for customers. The customers have always preferred our services and they firmly believe that as an android app developer in Hyderabad. </p>
                    </div>
                    <div className='android-card and-card3'>
                        <img src='https://img.freepik.com/premium-vector/man-woman-stand-near-big-smartphone_81522-143.jpg?w=996' className='android-app-mobile-img' />
                        <p className='android-app-description'>Emedha Technologies makes no stones unturned far :

                            In specifically hand picking some of the finest developers who have their expertise in conceptualizing, designing and implementing apps which brings in the finest architecture.
                            That executes business processes. Above all, the team has been quite passionate to have worked with several startups in the past and have stood firm in terms of establishing enterprise in the mobile marketplace</p>
                    </div>

                </div>
            </div>
            {/* <div className='footer-section'>
                <div className='responsive-container'>
                    <div className='about-us-section'>
                        <div className='about-us-card'>
                            <h1 className='about-us-head' style={{ color: '#0cb4f1' }}>About Us </h1>
                            <p className='about-us-description' style={{ color: 'white' }}>Technology has brought about a rapid turnaround in the dynamics of today's business. With the growing challenges both in the business as well as technological domains, there is a dire need to concentrate on core competencies, and to have shorter product lifecycles.</p>
                        </div>
                        <div>



                            <input type='text' id='subscribe' className='input-box' />

                            <button className='subscribe-btn'>Subscribe</button>
                        </div>
                    </div>
                    <div className='card-container'>
                        <div className='quick-links'>
                            <h1 className='quick-link-head'>Quick Links</h1>
                            <h4 className='quick-link-items'>Home</h4>
                            <h4 className='quick-link-items'>About Us</h4>
                            <h4 className='quick-link-items'>Services</h4>
                            <h4 className='quick-link-items'>Terms of service</h4>
                            <h4 className='quick-link-items'>Privacy Policy</h4>
                        </div>
                        <div className='contact-us' >
                            <h1 className='quick-link-head'>Contact Us</h1>
                            <p className='quick-link-items'>Flat #203, MYTRI APARTMENTS,
                                ECIL POST, Hyderabad-500062,
                                Telangana, INDIA
                                Phone: +91 9849164361
                                Email: info@emedha.com</p>

                        </div>
                        <div className='our-newslettter' style={{ marginBottom: 20 }}>
                            <h1 className='quick-link-head'>Our Newsletter</h1>
                            <p className='quick-link-items'>
                                Global movement springs from individuals. It takes an entire team united behind something big. Together, we work hard, we laugh a lot, we brainstorm nonstop, we use hundreds of Post-Its a week, and we give the best high-fives in town.</p>
                        </div>
                        <div className='d-flex flex-column folo-card' style={{ width: 310 }}>
                            <h1 className='folo'>Follows Us</h1>
                            <div className='d-flex flex-row align-items-center icons-container'>
                                <ImTwitter className='icons' style={{ backgroundColor: '#0cb4f1' }} />
                                <BsFacebook className='icons' style={{ backgroundColor: 'skyblue' }} />
                                <BsInstagram className='icons' style={{ backgroundColor: 'rgb(228, 207, 91)', color: 'black' }} />
                                <BsLinkedin className='icons' style={{ backgroundColor: '#1c1f18' }} />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='footer-line'></div>
                <h1 className='copy-right'><span style={{ color: '#0cb4f1' }}>© </span>Copyright eMedha. All Rights Reserved</h1>
            </div> */}
        </>
    )
}

export default AndriodAppService