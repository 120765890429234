import Enavbar from '../Enavbar'
import { BsClock, BsGlobe, BsRobot, BsWhatsapp, BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs'
import { ImTwitter } from 'react-icons/im'
import './index.css'
import Footer from '../Footer'


const PosService = () => {

    return (
        <>

            <div className='pos-container'>
                {/* <img src='https://emedha.com/assets/img/banner/pos.png' alt='webdesign-img' className='webdesign-img' /> */}
                <h1 className='our-work-head'>POS</h1>
                <div className='pos-card-container'>
                   
                    <div className='pos-card'>
                        <img src='https://img.freepik.com/premium-vector/nfc-technology-illustration-set_98402-1007.jpg?w=740' alt='pos-img' className='pos-design-card-img' />

                        <p className='pos-description'>
                            ePOS <span style={{color:'rgb(250, 111, 111)',fontWeight:'bold'}}>Point of Sale</span> application works both online ( Cloud) and offline ( locally).

                            It’s also integrated with payment gateway (all-in-one) .

                            Generate all kind of MIS Reports

                            Plug-n-play feature with any POS printer

                            Multi-Store Maintenance

                        </p>

                    </div>
                </div>
            </div>
           <Footer/>
        </>
    )
}


export default PosService