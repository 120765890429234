import { FaRegHandPointRight } from 'react-icons/fa'
import './index.css'
import Footer from '../Footer'
import { BsWhatsapp } from 'react-icons/bs'


const WhatsappServices = () => {

    return (
        <>
        <div className='pos-container'>
            {/* <img src='https://emedha.com/assets/img/banner/pos.png' alt='webdesign-img' className='webdesign-img' /> */}
            <h1 className='whatsapp-main-heading'>What’sapp API <BsWhatsapp style={{color:'green',fontSize:30,marginLeft:18,marginBottom:10}}/></h1>
            <div className='pos-card-container'>

                <div className='whatsapp-card'>
                    <img src='https://img.freepik.com/free-vector/new-notifications-concept-illustration_114360-4323.jpg?w=740&t=st=1681823306~exp=1681823906~hmac=84a960b7ba3eb1ef772efe0e8f934432955cbb20ea659ccd5a709d703e7a79d3' alt='pos-img' className='pos-design-card-img' />
                   <div>
                    <p className='whatspp-description'>
                        Basically designed for SMEs/MSMEs /Small Business Traders and professionals with Multiple Devices
                        Paid Services by What’s app for the Small and Medium Enterprise Customers </p>
                    <h1 className='whatsapp-card-heading'>Business What’s app Features</h1>
                    <p style={{color:'white'}}><FaRegHandPointRight style={{color:'green',marginRight:10}}/>One-to-One Interaction thus avoid group</p>

                    <p style={{color:'white'}}><FaRegHandPointRight style={{color:'green',marginRight:10}}/>Can Push & Pull Data with a customised Middle Layer Dash Board</p>

                    <p style={{color:'white'}}><FaRegHandPointRight style={{color:'green',marginRight:10}}/>Geo-Location Share with Call to Action Buttons and much more</p>
                    </div>

                </div>
            </div >
        </div >

        <Footer/>
        </>
    )
}


export default WhatsappServices