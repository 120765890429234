import Enavbar from '../Enavbar'
import { BsClock, BsGlobe, BsRobot, BsWhatsapp,BsFacebook,BsInstagram,BsLinkedin } from 'react-icons/bs'
import {ImTwitter} from 'react-icons/im'
import './index.css'
import Footer from '../Footer'


const WebDesignService=()=>{

    return(
        <>
        {/* <Enavbar/> */}
        <div className='approach-container'>
            <img src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ea93c3ef-04c8-4951-bc0d-0764028e81d0/d7rb1zc-4bf9c710-10ed-49a3-9f8c-6aa55f688393.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2VhOTNjM2VmLTA0YzgtNDk1MS1iYzBkLTA3NjQwMjhlODFkMFwvZDdyYjF6Yy00YmY5YzcxMC0xMGVkLTQ5YTMtOWY4Yy02YWE1NWY2ODgzOTMuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.otcSUkBNeZyjdrIVTpoPYmDQicq52kmPp1AYdmNhySk' alt='webdesign-img' className='webdesign-img' />
            <div className='work-process-container'>
                <h1 className='our-work-head'>The conversion of a website to a mobile ones are an inevitable trend today</h1>
                <div className='webdesign-card'>
                    <img src='https://emedha.com/assets/img/workprocess/serveviceb/Web%20Designing%201-min.png' alt='web-design-card-img' className='web-design-card-img' />
                   <div className='web-design-description-card m-2'>
                    <h1 className='our-resource-card-heading'>Identify Needs</h1>
                    <p className='identiy-description'>

                    It is estimated that in the digital world, nearly half of all the internet traffic that is generated has been found to originate from a spectrum of hand-held devices. In this particular transition, one can really understand that if your business website becomes a responsive or the mobile friendly website there would be a fair chance for your website to tap the potential web traffic and seamlessly convert them into sales funnel.
                  </p>
                </div>
                </div>
           
                <div className='webdesign-card'>
                   
                   <div className='web-design-description-card m-2'>
                    <h1 className='our-resource-card-heading'>One cannot compete in the digital ecosystem with a traditional website conceived yesterday</h1>
                    <p className='identiy-description'>

                    As the digital ecosystem keeps evolving with the latest digital trends, the traditional websites that is being conceived and developed yesterday doesn't hold good to work on hand-held devices which are released in the market today. In this direction, one must have the best Hyderabad web designers in place who have the competency to renovate your traditional website to a robust responsive website with all the features that impeccably matches to work on a spectrum of mobile devices.</p>
                </div>
                <img src='https://img.freepik.com/free-vector/data-analyst-oversees-governs-income-expenses-with-magnifier-financial-management-system-finance-software-it-management-tool-concept_335657-1891.jpg?w=996&t=st=1681798744~exp=1681799344~hmac=e08994c1172e51f8041b7f16df9f462ce3addebc6dd1c4de20de7e08dd5ca741' alt='web-design-card-img' className='web-design-card-img' />
                </div>
        
                <div className='webdesign-card'>
                    <img src='https://emedha.com/assets/img/workprocess/serveviceb/Web%20Designing%203-min.png' alt='web-design-card-img' className='web-design-card-img' />
                   <div className='web-design-description-card m-2'>
                    <h1 className='our-resource-card-heading'>The technology partner that stresses on increasing web traffic</h1>
                    <p className='identiy-description'>

                    Emedha Technologies has been offering affordable solution for responsive websites, our goal have always been towards conceiving and developing custom web responsive sites which enables the online onlookers to have the most ideal viewing experience. In the pursuit of delivering the best of the website design, we are reputed in the market to become the best responsive web design company in Hyderabad which produces two variants of websites:.</p>
                </div>
                </div>
          
                <div className='webdesign-card'>
                    <div className='web-design-description-card m-2'>
                    <h1 className='our-resource-card-heading'>Our Approach to increase sales for Small Scale Industries</h1>
                    <p className='identiy-description'>

                    Our approach has always been to help small scale industries and start up entrepreneurs in reaching their clients over the web through responsive sites. The team that we have hand-picked have their competency in designing responsive site which loads quite easily over the hand-held devices and invariably use the smartphone to navigate across the web functionally quite easily.</p>
                </div>
                <img src='https://img.freepik.com/free-vector/analyst-working-laptop-with-automation-process-business-process-automation-business-process-workflow-automated-business-system-concept_335657-400.jpg?w=996&t=st=1681799520~exp=1681800120~hmac=1bab175a8c0595263b450d9927bd9fe60f93a53392d2cac4f6a7910edb22ec99' alt='web-design-card-img' className='web-design-card-img' />

                </div>
                <div className='webdesign-card'>
                    <img src='https://img.freepik.com/free-vector/professional-gamers-table-taking-part-e-sport-competition-trophy-e-sports-tournament-game-official-event-e-sports-championship-concept_335657-57.jpg?w=996&t=st=1681799861~exp=1681800461~hmac=37b697fe46eb9298183b856e39008706e6d72673ef32c2ff796f0904beb4c039' alt='web-design-card-img' className='web-design-card-img' />
                   <div className='web-design-description-card m-2'>
                    <h1 className='our-resource-card-heading'>Our milestones which are worth mentioning</h1>
                    <p className='identiy-description'>

                    In today's trend online users are navigating through variety of devices to access and use certain complex functions such online purchase transactions. The responsive approach to smart phones has always made the customer to have a value for their investment in the website.</p>
                </div>
                </div>
              
               
            </div>
        </div>
        <Footer/>
    </>
    )
}


export default WebDesignService