import Container from 'react-bootstrap/Container';
import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { GiHamburgerMenu } from 'react-icons/gi'
import { useAppStore } from '../AppStore/index'
import { Route, Routes, Link } from 'react-router-dom'
// import { useState } from 'react';
import './index.css'
import Home from '../Home';
import About from '../About';
import Portfolio from '../Portfolio';
import Contact from '../Contact';
import Approach from '../Approach';
import { useState } from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md';
import WebDesignService from '../WebdesignService';
import PosService from '../PosService';
import AndriodAppService from '../AndroidAppDev';
import BotServices from '../Bot';
import SeoServices from '../SeoServices';
import SmmServices from '../SmmServices';
import WhatsappServices from '../WhatsappService';
import { IoMdClose } from 'react-icons/io';
import navbarlogo from '../Images/navbarlogo.png'


const Enavbar = () => {

  const onToggleSidebar = () => {
    updateState(!dopen)
  }

  const updateState = useAppStore((state) => state.updateOpen)

  const dopen = useAppStore((state) => state.dopen)

  console.log(dopen)

  const [isWebDevSideDrop, setIsSideWebDrop] = useState(false)

  const dropOpen1 = () => {
    setIsSideWebDrop(prevState=>!prevState)
  }


  return (
    <>
      <Navbar  variant='dark' style={{ hight: 120, paddingLeft: 10, color: 'white' }} fixed="top" className="navbar1">
        <Container>
          <Navbar>

            <Navbar.Brand href="#home">
              <img
                src={navbarlogo}
                className='emedha-logo'

                alt="Emedha logo"
              />
            </Navbar.Brand>

          </Navbar>

          <div className='small-md-screen'>
           {dopen?
            <IoMdClose className='icon' style={{fontSize:40,color:'#1c1f18'}}  onClick={onToggleSidebar}/>:<GiHamburgerMenu style={{ color: 'white', fontSize: 35 }} onClick={onToggleSidebar} />}
            
          </div>



          <div className='large-screen'>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" >
              <Nav className='nav-items' style={{ fontWeight: 'bold', marginRight: 15 }}>
                <Link to="/" style={{ textDecoration: 'none', color: 'gray' }}><Nav style={{ fontWeight: 'bold', marginRight: 15 }}>Home</Nav></Link>
                <Link to='/about' style={{ textDecoration: 'none', color: 'gray' }}><Nav style={{ fontWeight: 'bold', marginRight: 15 }}>About</Nav></Link>
                <Link to='/approach' style={{ textDecoration: 'none', color: 'gray' }}><Nav href="#approach" style={{ ontWeight: 'bold', marginRight: 15 }}>Approach</Nav></Link>

                {/* <NavDropdown title="Services" id="basic-nav-dropdown" className="dropdown">
                  <NavDropdown.Item href="#action/3.1" className="dropdown-items">Web Designing</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2" className="dropdown-items" onClick={dropOpen1}>Web App Development <MdKeyboardArrowRight style={{ marginRight: 'auto' }} /></NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3" className="dropdown-items">Mobile App Development <MdKeyboardArrowRight style={{ marginLeft: 'auto' }} /></NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.4" className="dropdown-items">Digital Marketing <MdKeyboardArrowRight style={{ marginLeft: 'auto' }} /></NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.5" className="dropdown-items">Bot</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.6" className="dropdown-items">What's App Business API</NavDropdown.Item>
                </NavDropdown> */}
                <div style={{marginRight:12}}>

                    
                  <Dropdown title="Services" className='new-drop'>
                   <Link to="/webdesign" style={{ textDecoration: 'none', color: 'gray' }}><Dropdown.Item>Web Designing</Dropdown.Item></Link> 
                   
                    <Dropdown.Menu title="Web App Development">
                      <div style={{marginLeft:10,width:150}}>
                      <Link to='/pos' style={{ textDecoration: 'none', color: 'gray' }}><Dropdown.Item>POS</Dropdown.Item></Link> 
                      <Dropdown.Item>SMM</Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                   
                    <Dropdown.Menu title="Mobile App Development">
                      {/* <Dropdown.Menu title="React" disabled> */}
                    <Link to='/android' style={{ textDecoration: 'none', color: 'gray' }}><Dropdown.Item >Android App Development</Dropdown.Item></Link>  
                      <Dropdown.Item>iOS App Development</Dropdown.Item>
                    </Dropdown.Menu>
                    <Dropdown.Menu title="Digital Marketing" styel={{marginLeft:20}}>
                      {/* <Dropdown.Menu title="React" disabled> */}
                    <Link to='/seo' style={{ textDecoration: 'none', color: 'gray' }}><Dropdown.Item>SEO</Dropdown.Item></Link>  
                      <Dropdown.Item>SEM</Dropdown.Item>
                    <Link to='/smm' style={{ textDecoration: 'none', color: 'gray' }}><Dropdown.Item>SMM</Dropdown.Item></Link> 
                    </Dropdown.Menu>
                   <Link to='/whatsapp' style={{ textDecoration: 'none', color: 'gray' }}><Dropdown.Item >What's App Business API</Dropdown.Item></Link> 
                    <Link to="/bot" style={{ textDecoration: 'none', color: 'gray' }}><Dropdown.Item>Bot</Dropdown.Item></Link>


                  </Dropdown>
                </div>



                <Link to='/portfolio' style={{ textDecoration: 'none', color: 'gray' }}><Nav style={{ fontWeight: 'bold', marginRight: 15 }}>Portfolio</Nav></Link>
                <Link to='/contact' style={{ textDecoration: 'none', color: 'gray' }}><Nav style={{ fontWeight: 'bold', marginRight: 15 }}>Contact</Nav></Link>
              </Nav>
            </Navbar.Collapse>
          </div>

        </Container>

      </Navbar>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path='/about' element={<About></About>} />
        <Route path='/approach' element={<Approach></Approach>} />
        <Route path='/portfolio' element={<Portfolio></Portfolio>} />
        <Route path='/contact' element={<Contact></Contact>} />
        <Route path='/webdesign' element={<WebDesignService></WebDesignService>}/>
        <Route path='/pos' element={<PosService></PosService>}/>
        <Route path='/android' element={<AndriodAppService></AndriodAppService>}/>
        <Route path='/bot' element={<BotServices></BotServices>}/>
        <Route path='/seo' element={<SeoServices></SeoServices>}/>
        <Route path='/smm' element={<SmmServices></SmmServices>}/>
        <Route path='/whatsapp' element={<WhatsappServices></WhatsappServices>}/>
      </Routes>
    </>
  );
}



export default Enavbar